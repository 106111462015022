import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import 'vant/lib/index.css';
import axios from "axios"
// axios.defaults.baseURL = 'https://gxkj.elinktime.cn'
axios.defaults.baseURL = "https://xcbt.elinktime.cn/teahouse/public/index.php"

const app = createApp(App)

app.config.globalProperties.$http = axios
app.use(router).mount("#app");
